
































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import FeaturedGigCard from '@Marketing/components/content/featured-gigs/FeaturedGigCard.vue'
import Cta from '@UI/components/cta/Cta.vue'
import {
  CmsFeaturedEventCollection,
  Event,
  Maybe,
  CmsImage,
} from '@Core/@types/skyway'

@Component({
  inheritAttrs: false,
  components: {
    FeaturedGigCard,
    Cta,
  },
})
export default class FeaturedGigCollection extends Vue {
  @Prop({ type: Object, required: true }) data!: CmsFeaturedEventCollection

  protected activeCard: string | null = null
  protected ctaData = {
    title: 'All gigs & Events',
    link: {
      url: `${this.$config.get('URLS').whats_on}`,
      slug: `${this.$config.get('URLS').whats_on}`,
      title: 'All gigs & Events',
    },
    extra: {
      type: 'primary',
    },
  }

  updateImage(val: string | null): void {
    if (val) {
      this.activeCard = val
    }
  }

  get eventsWithInstances(): Event[] | null | undefined {
    return (
      this.data.events &&
      this.data.events.reduce((eventsArr: Event[], event: Maybe<Event>) => {
        if (event && event.instances) {
          eventsArr.push(event)
        }

        return eventsArr
      }, [])
    )
  }

  get eventImages(): CmsImage[] | [] {
    if (this.eventsWithInstances) {
      return this.eventsWithInstances.map((event) => {
        return {
          title: event!.title!,
          sizes: event.images && event.images.sizes,
        }
      })
    }

    return []
  }

  mounted(): void {
    if (this.eventImages) {
      this.activeCard =
        (this.eventImages.length && this.eventImages[0].title) || ''
    }
  }
}
