









































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import { Event, Instance } from '@Core/@types/skyway'
import { formatSummarisedDates } from '@Marketing/helpers/dates/index'
import ArrowRightSvg from '~/static/images/icons/arrow.svg?inline'

@Component({
  components: {
    ArrowRightSvg,
  },
})
export default class FeaturedGigCard extends Vue {
  @Prop({ type: Object, required: true }) data!: Event

  @Emit('updateImage')
  handleHoverOrFocus() {
    return this.title
  }

  get title(): string | undefined | null {
    return this.data.title
  }

  get subtitle(): string | null {
    return this.data.subtitle ? this.data.subtitle : null
  }

  get almostGone(): boolean {
    return this.data.extra.almost_gone || false
  }

  get hasInstances(): boolean {
    return Boolean(this.data.instance_count && this.data.instance_count > 0)
  }

  get nextDatetime(): string | null {
    if (
      this.hasInstances &&
      this.futureInstances[0] &&
      this.futureInstances[0].date_time
    ) {
      return this.$moment(this.futureInstances[0].date_time).format('DD MMM YY')
    }

    return null
  }

  get additionalInstanceCount(): number {
    return this.futureInstances.length - 1
  }

  get futureInstances(): Instance[] | [] {
    const { instances, first_date } = this.data as Event

    if (instances && first_date) {
      return instances.filter(({ date_time }) =>
        this.$moment(date_time).isSameOrAfter()
      )
    } else {
      return []
    }
  }

  get hasAdditionalDates(): boolean {
    return this.futureInstances.length > 1
  }

  get firstAdditionalDate(): string | null {
    if (this.hasAdditionalDates) {
      return this.futureInstances[1].date_time
    } else {
      return null
    }
  }

  get additionalDates(): string | null {
    const { last_date, type } = this.data

    if (
      this.hasAdditionalDates &&
      this.firstAdditionalDate &&
      this.firstAdditionalDate !== last_date
    ) {
      return formatSummarisedDates(
        this.firstAdditionalDate,
        last_date,
        this.additionalInstanceCount,
        this.$moment,
        type
      )
    } else {
      return null
    }
  }

  get seriesTitle(): string | null {
    if (this.data.series && this.data.series.title) {
      return this.data.series.title
    }

    return null
  }

  get href(): string {
    return this.data.alias
      ? `${this.$config.get('URLS').whats_on}${this.data.alias}`
      : `${this.$config.get('URLS').whats_on}${this.data.event_ref}`
  }
}
